import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2d3261',
        secondary: '#f18609',
        PENDING: '#f18609',
        CANCELLED: '#848484',
        ACTIVE: '#1B5E20',
        FINISH: '#2d3261',
        INVOICED: '#2d3261',
        PLANNED: '#f18609',
        EXECUTED: '#2d3261'
      }
    }
  }
})
